<template>
    <div>블루월넛 결제
        <p><tt id="results1"></tt></p>
        <p><tt id="results2"></tt></p>
    </div>
</template>
<script>
import { defineComponent } from "@vue/composition-api";
import $ from "jquery";

export default defineComponent({
  name: 'ReturnBill',
  mounted () {
    // console.log("블루월넛");
    this.getReturnBill();
    var data = $("form").serialize();
    $("#results1").text(data);
    var json = JSON.stringify(data);

    // console.log(json);
    $("#results2").text(json);
  },
  data () {
    return {
      mid: "",
      billKey: "",
      cardBrandNm: "",
      cardKindCd: "",
      cardBin: "",
      mallUserId: "",
      cardCode: "",
      cardName: "",
      catPrintCardName: ""
    };
  },
  methods: {
    getReturnBill () {
      console.log("helloWorld");
    }
  }
});
</script>
